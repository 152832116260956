<template>
  <CRow>
    <CModal
        :title="submitType === 'Update' ? $lang.discipline.crud.edit : $lang.discipline.crud.create"
        size="lg"
        :show.sync="largeModal"
        :no-close-on-backdrop="true"
        color="primary">
      <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>{{ err_msg }}</CAlert>
      <CForm novalidate @submit.prevent="onSubmit">
        <CCardBody>
          <div class="form-group" :class="{ 'form-group--error': $v.discipline.programmeName.$error }">
            <label class="form__label">{{ $lang.discipline.form.programmeName }}
              <required_span/>
            </label>
            <v-select
                :options="ProgrammeOptions"
                v-model="discipline.programmeName"
                @option:selected="loadStream"
            ></v-select>
            <small
                class="error"
                v-if="$v.discipline.programmeName.$error && !$v.discipline.programmeName.required"
            >{{ $lang.discipline.validation.required.programmeName }}</small
            >
          </div>
          <div
              class="form-group"
              :class="{ 'form-group--error': $v.discipline.streamName.$error }">
            <label class="form__label">{{ $lang.discipline.form.streamName }}
              <required_span/>
            </label>
            <v-select :options="StreamOptions" v-model="discipline.streamName">
            </v-select>
            <small
                class="error"
                v-if="$v.discipline.streamName.$error && !$v.discipline.streamName.required">
              {{ $lang.discipline.validation.required.streamName }}</small>
          </div>
          <div
              class="form-group"
              :class="{ 'form-group--error': $v.discipline.name.$error }"
          >
            <label class="form__label"
            >{{ $lang.discipline.form.name }}
              <required_span/>
            </label>
            <input
                :maxlength="maxlength.name"
                :placeholder="$lang.discipline.form.name"
                class="form-control"
                v-model="discipline.name"
            />
            <small
                class="error"
                v-if="$v.discipline.name.$error && !$v.discipline.name.required"
            >{{ $lang.discipline.validation.required.name }}</small>
          </div>

          <div class="form-group" :class="{ 'form-group--error': '' }">
            <label class="form__label">{{ $lang.discipline.form.isActive }} </label>
            <input
                type="checkbox"
                :placeholder="$lang.discipline.form.isActive"
                :value="1"
                @change="changedIsActive"
                v-model="discipline.isActive"
            />
          </div>
        </CCardBody>
        <CCardFooter>
          <CButton
              type="submit"
              size="sm"
              color="primary"
              :disabled="submitted">
            <CIcon name="cil-check-circle"/>
            {{ $lang.buttons.general.crud.submit }}
          </CButton>
        </CCardFooter>
      </CForm>
      <template #footer style="display: none">
        <CButton @click="darkModal = false" color="danger" style="display: none"
        >Discard
        </CButton>
        <CButton
            @click="darkModal = false"
            color="success"
            style="display: none"
        >Accept
        </CButton>
      </template>
    </CModal>

    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.discipline.title }}</strong>
            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButton
                    color="primary"
                    v-on:click="createTableRow"
                    v-if="checkPermission('create-discipline')"
                    style="margin-right: 10px"
                >
                  {{ $lang.buttons.general.crud.create }}
                </CButton>
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton
                      color="danger"
                      v-c-tooltip="$lang.buttons.export.pdf"
                      v-on:click="exports(self, 'pdf', module, 'Discipline')"
                  >
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv
                      class="btn btn-success"
                      v-c-tooltip="$lang.buttons.export.excel"
                      :json-data="json_data"
                      :labels="json_label"
                      :csv-title="json_title"
                  >
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <CAlert
              color="success"
              closeButton
              :show.sync="dismissCountDownS"
              fade
          >{{ alertMessage }}
          </CAlert
          >
          <v-server-table
              :columns="columns"
              url="/discipline/list"
              :options="options"
              ref="myTable"
              @loaded="exports(self, 'Excel', module, 'Discipline')"
          >
            <template #status="data">
              <CBadge :color="statusBudget(data.row.status)">{{ data.row.status }}</CBadge>
            </template>
            <template #name="data">
              <span :title="data.row.name">{{
                  trimfunction(data.row.name, 20)
                }}</span>
            </template>
            <template #programme="data">
              <span :title="data.row.programme.name">{{
                  trimfunction(data.row.programme.name, 20)
                }}</span>
            </template>
            <template #stream="data">
              <span :title="data.row.stream.name">{{ trimfunction(data.row.stream.name, 20) }}</span>
            </template>
            <template #actions="data">
              <CButton
                  color="success"
                  v-c-tooltip="$lang.buttons.general.crud.edit"
                  v-on:click="editTableRow(data.row.id)"
                  v-if="checkPermission('edit-discipline')"
              >
                <i class="fas fa-pencil-alt"></i>
              </CButton>
              <CButton
                  color="danger"
                  v-c-tooltip="$lang.buttons.general.crud.delete"
                  v-on:click="deletePopup(data.row.id)"
                  v-if="checkPermission('delete-discipline')"
              >
                <i class="fas fa-trash"></i>
              </CButton>
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {stream, discipline, programme} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import {minLength, required} from "vuelidate/lib/validators";
import required_span from "../../components/layouts/general/required-span";
import VueToasted from "vue-toasted";
import VueJsonToCsv from "vue-json-to-csv";
import {Maxlength} from "../../store/maxlength";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

Vue.use(VueToasted);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);
export default {
  name: "Discipline",
  mixins: [Mixin],
  components: {
    required_span,
    VueJsonToCsv,
  },
  data() {
    return {
      submitted: false,
      json_data: [],
      json_label: {},
      json_title: "",
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "",
      dismissCountDown: 0,
      ProgrammeOptions: [],
      programmeModule: programme,
      streamModule: stream,
      module: discipline,
      dismissCountDownS: 0,
      StreamOptions: [],
      discipline: {
        programmeName: "",
        streamName: "",
        name: "",
        isActive: 1,
      },
      maxlength: {
        name: Maxlength.discipline.name,
      },
      self: this,
      msg: "",
      items: [],
      activePage: 1,
      columns: ["programme", "stream", "name", "status", "actions"],
      data: [],
      options: {
        headings: {
          programme: this.$lang.discipline.table.programmeName,
          stream: this.$lang.discipline.table.streamName,
          name: this.$lang.discipline.table.name,
          status: this.$lang.discipline.table.isActive,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ["programme", "stream", "name"],
        sortable: ["programme", "stream", "name"],
        filterable: ["programme", "stream", "name"],
      },
    };
  },
  validations: {
    discipline: {
      name: {
        required,
        minLength: minLength(1),
      },
      programmeName: {
        required,
      },
      streamName: {
        required,
      },
    },
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    axios.get(this.listUrlApi(this.programmeModule)).then((response) => {
      response.data.data.map(function (value, key) {
        self.ProgrammeOptions.push({value: value.id, label: value.name});
      });
    });
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    changedIsActive(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.discipline.isActive = 1;
      } else {
        self.discipline.isActive = 0;
      }
    },
    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel,
      }).then((result) => {
        if (result.value) {
          let self = this;
          axios
              .delete(this.deleteUrlWeb(this.module, item), self)
              .then(function (response) {
                let responseData = response.data;
                if (responseData.code === 200) {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.dismissCountDownS = 10;
                } else {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.messageColor = "danger";
                  self.dismissCountDownS = 10;
                }
              }).catch(function (error) {
            let data;
            if (error.response.data.error) {
              data = error.response.data.error.toString();
            } else {
              data = error.response.data.message;
            }
            self.alertMessage = data;
            self.messageColor = "danger";
            self.dismissCountDownS = 10;
          });
        }
      });
    },
    createTableRow() {
      let self = this;
      self.$v.$reset();
      self.submitType = "Create";
      self.discipline.name = "";
      self.discipline.streamName = "";
      self.discipline.programmeName = "";
      self.largeModal = true;
    },
    editTableRow(item) {
      this.loadStream();
      let self = this;
      self.$v.$reset();
      self.submitType = "Update";
      const id = item;
      self.editId = item;
      axios
          .get(this.viewUrlApi(this.module, id), self.model)
          .then(function (response) {
            if (response) {
              let responseData = response.data.data;
              self.discipline.name = responseData.name;
              self.discipline.isActive = responseData.isActive;
              self.discipline.programmeName = {
                value: responseData.programme.id,
                label: responseData.programme.name,
              };
              self.discipline.streamName = {
                value: responseData.stream.id,
                label: responseData.stream.name,
              };
            } else {
              self.data = [];
            }
          });
      self.largeModal = true;

    },

    pageChange(val) {
      this.$router.push({query: {page: val}});
    },
    loadStream() {
      let self = this;
      var programmeId = self.discipline.programmeName.value;
      self.discipline.streamName = '';
      axios
          .get('/stream/list/'+programmeId)
          .then((response) => {
            self.StreamOptions = [];
            response.data.data.map(function (value, key) {
              self.StreamOptions.push({value: value.id, label: value.name});
            });
          });
    },
    onSubmit() {
      let self = this;
      this.$v.discipline.$touch();
      if (this.$v.discipline.$invalid) {
        self.submitted = false; //Enable Button
        this.submitStatus = "ERROR";
      } else {
        self.submitted = true; //Disable Button
        this.submitStatus = "PENDING";
        if (this.submitType === "Update") {
          setTimeout(() => {
            let self = this;
            const postData = {
              name: self.discipline.name,
              programmeId: self.discipline.programmeName.value,
              streamId: self.discipline.streamName.value,
              isActive: self.discipline.isActive,
            };
            axios
                .post(this.updateUrlweb(this.module, self.editId), postData)
                .then(function (response) {
                  self.submitted = false; //Enable Button
                  if (response.data.code === 200) {
                    self.alertMessage = response.data.message;
                    self.dismissCountDownS = 10;
                    self.largeModal = false;
                    self.$refs.myTable.refresh();
                  } else {
                    self.err_msg = response.data.message;
                    self.dismissCountDown = 10;
                  }
                })
                .catch(function (error) {
                  let data;
                  if (error.response.data.error) {
                    data = error.response.data.error.toString();
                  } else {
                    data = error.response.data.message;
                  }
                  self.err_msg = data;
                  self.dismissCountDown = 10;
                  self.submitted = false; //Enable Button
                });
          }, 500);
        } else {
          setTimeout(() => {
            let self = this;
            const postData = {
              name: self.discipline.name,
              programmeId: self.discipline.programmeName.value,
              streamId: self.discipline.streamName.value,
              isActive: self.discipline.isActive,
            };
            axios
                .post(this.createUrlWeb(this.module), postData)
                .then(function (response) {
                  self.submitted = false; //Enable Button
                  if (response.data.code === 200) {
                    self.alertMessage = response.data.message;
                    self.dismissCountDownS = 10;
                    self.largeModal = false;
                    self.$refs.myTable.refresh();
                  } else {
                    self.err_msg = response.data.message;
                    self.dismissCountDown = 10;
                  }
                })
                .catch(function (error) {
                  let data;
                  if (error.response.data.error) {
                    data = error.response.data.error.toString();
                  } else {
                    data = error.response.data.message;
                  }
                  self.err_msg = data;
                  self.dismissCountDown = 10;
                  self.submitted = false; //Enable Button
                });
          }, 500);
        }
      }
    },
  },
};
</script>
